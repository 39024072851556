require(["jquery"], function ($) {
    'use strict';

    $(document).on("minicart:addToCart", function (e, data) {
        window.dataLayer = window.dataLayer || [];

        let dataToPush = {
            event: "crosssell_add"
        };

        // data should be a FormData Object
        if (typeof data.get === "function") {
            dataToPush = {
                ...dataToPush,
                ...{
                    ecommerce: {
                        add: {
                            crosssell: {
                                sku: data.get('sku'),
                                name: data.get('name')
                            }
                        }
                    }
                }
            };
        }

        window.dataLayer.push(dataToPush);
    });
});
